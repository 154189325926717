import React, { useState } from 'react';
import { Dialog, Menu } from '@headlessui/react';
import { Loader } from '../shared/Loader';
import {
  AddToFolderIcon,
  CheckmarkIcon,
  PlusIcon,
  RemoveFromFolderIcon
} from '../../modules/Icons';
import MenuItemsCustomWrapper from '../helpers/MenuItemsCustomWrapper';
import WishlistEventNotification from './WishlistEventNotification';
import CreateOrUpdateWishlistForm from './CreateOrUpdateWishlistForm';
import { useSession } from 'next-auth/react';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../state/actions/messagesActions';
import { addNotification } from '../../state/actions/notificationsActions';
import { addToWishlist, removeFromWishlist } from '../../state/actions/wishlistsActions';
import { AUTH_ACTIONS } from '../../constants/actions';
import { MAX_WISHLIST_ITEMS_COUNT } from '../../constants/ui';
const { AUTH_SHOW_LOGIN } = AUTH_ACTIONS;
import YesNoPromptDialog from '../shared/YesNoPromptDialog';
import getWishlists from '../../hooks/getWishlists';
import { ThreeDots } from 'react-loader-spinner';
import { WishlistResponse, WishlistProductResponse } from '../../types/api/WishlistTypes';
import algoliaHelpers from '../../helpers/algolia';

function AddToWishlistButton(props) {
  const {
    product,
    type,
    contextWishlistId,
    buttonClassName,
    popoverClassName,
    onWishlistDropdownToggle = (value: boolean) => null,
    onProductRemovedFromWishlist = (product: any) => null,
    onLoginDialogOpen = () => {},
    setIsOpen,
    component
  } = props;
  const [status, setStatus] = useState('inactive');
  const [removeFromWishlistConfirmationOpen, setRemoveFromWishlistConfirmationOpen] =
    useState(false);

  const session = useSession() as any;
  const user = session?.data?.user;
  const dispatch = useDispatch();
  const wishlistsState = getWishlists(status === 'active');
  const { pushAddToWishlistEvent } = algoliaHelpers;

  const productExists = (wishlist: WishlistResponse): boolean => {
    return (
      wishlist?.products?.filter((p: WishlistProductResponse) => p.id == product.id).length > 0
    );
  };

  function removeProduct(wishlistId: number) {
    setStatus('loading');
    dispatch({
      type: '',
      api: {
        method: 'DELETE',
        url: `/wishlists/${wishlistId}/products/${product.id}`,
        data: { product_id: product.id, id: wishlistId },
        headers: { authorization: user.token },
        onError: (res) => {
          dispatch(
            addMessage({
              type: 'error',
              body: 'Something went wrong deleting from wishlist. Please try again later.'
            })
          );
          setStatus('inactive');
          return false;
        },
        onSuccess: (res) => {
          setStatus('active');
          const wishlist = wishlistsState.data?.find((wishlist) => wishlist.id === wishlistId);
          if (wishlist) {
            dispatch(
              addNotification({
                body: (
                  <WishlistEventNotification
                    product={product}
                    wishlist={wishlist}
                    eventType="removeFromWishlist"
                  />
                )
              })
            );
            dispatch(removeFromWishlist(wishlist, product));
            onProductRemovedFromWishlist(product);
          }
          onProductRemovedFromWishlist(product);
        }
      }
    });
    return false;
  }


  function addProduct(wishlist: WishlistResponse) {
    if (productExists(wishlist)) {
      return removeProduct(wishlist.id);
    }
    if (wishlist.products?.length >= MAX_WISHLIST_ITEMS_COUNT) {
      dispatch(
        addMessage({
          type: 'error',
          body: `Maximum amount of items in ${wishlist.name} has been reached. Can't add more than ${MAX_WISHLIST_ITEMS_COUNT} items to a wishlist.`
        })
      );
      return;
    }
    setStatus('loading');
    dispatch({
      type: '',
      api: {
        method: 'POST',
        url: `/wishlists/${wishlist.id}/add_product`,
        data: { product_id: product.id, id: wishlist.id },
        headers: { authorization: user.token },
        onError: () => {
          dispatch(
            addMessage({
              type: 'error',
              body: 'Something went wrong saving to wishlist. Please try again later.'
            })
          );
          setStatus('inactive');
          return false;
        },
        onSuccess: async () => {
          setStatus('active');
          dispatch(addToWishlist(wishlist, product));
          dispatch(
            addNotification({
              body: (
                <WishlistEventNotification
                  product={product}
                  wishlist={wishlist}
                  eventType="addToWishlist"
                />
              )
            })
          );
          await pushAddToWishlistEvent([product.id], user);
        }
      }
    });
    return false;
  }

  const itemStyles = { textOverflow: 'ellipsis' };

  return (
    <div cy-test-id="add-to-wishlist-button" className={props.className}>
      <Menu>
        {({ close }) => (
          <>
            <Menu.Button as="div">
              {type == 'button' && (
                <button
                  onClick={(e) => {
                    if (!user) e.preventDefault();
                    setStatus('active');
                  }}
                  className={
                    buttonClassName ||
                    'text-[16px] stroke-a-light-gray text-a-light-gray bg-white-5 py-2 px-3 rounded-[5px] hover:text-white hover:stroke-white hover:bg-white-10'
                  }>
                  <AddToFolderIcon className="inline" /> Add to Wishlist
                </button>
              )}

              {type == 'icon' && (
                <AddToFolderIcon
                  className="stroke-inherit"
                  onClick={(e) => {
                    setStatus('active');
                    if (!user) onLoginDialogOpen();
                    return false;
                  }}
                />
              )}

              {type == 'remove' && (
                <RemoveFromFolderIcon
                  className="w-full h-full stroke-inherit"
                  onClick={(e) => {
                    setStatus('removeFromWishlist');
                    return false;
                  }}
                />
              )}
            </Menu.Button>
            {!!user && (
              <MenuItemsCustomWrapper
                propsToWatchForResize={[wishlistsState.data, status]}
                dropdownClassName={`${popoverClassName} ${component === 'collection-page-sidebar' ? '!w-full !max-w-[308px] lg:!max-w-[328px] 2xl:!max-w-[368px]' : 'w-full max-w-[216px] lg:max-w-[337px]'} !z-30 rounded-[5px] shadow-xl`}
                onDropdownToggle={onWishlistDropdownToggle}>
                {wishlistsState.loading && (
                  <span
                    style={{ marginLeft: '50%', transform: 'translateX(-24px)' }}
                    className="block py-2">
                    <ThreeDots color="#00A1E0" height="48" width="48" radius="6" />
                  </span>
                )}
                {status !== 'addWishlist' &&
                  status !== 'removeFromWishlist' &&
                  !!wishlistsState.data &&
                  wishlistsState.data.map((wishlist, k) => (
                    <Menu.Item
                      as="div"
                      key={k}
                      className={`pb-2.5 ${k === 0 ? 'pt-[15px]' : 'pt-2.5'} pl-4 ${
                        productExists(wishlist) ? 'pr-12' : 'pr-5'
                      } hover:bg-a-darker-gray cursor-pointer relative`}
                      onClick={(e) => {
                        e.preventDefault();
                        addProduct(wishlist);
                        close();
                      }}>
                      <img
                        className="w-[42px] h-[27px] mr-2.5 inline-block"
                        src={
                          wishlist.products?.length
                            ? wishlist.products[0].image
                            : '/img/placeholder-wishlist.png'
                        }
                      />
                      <span
                        className="inline-block overflow-hidden align-middle whitespace-nowrap leading-22"
                        style={{
                          ...itemStyles,
                          maxWidth: productExists(wishlist) ? '180px' : '210px'
                        }}>
                        {wishlist.name}
                      </span>
                      {productExists(wishlist) && (
                        <CheckmarkIcon
                          className={`absolute ${
                            k === 0 ? 'top-[20px]' : 'top-[14px]'
                          } right-[15px] !stroke-[#00DA5E]`}
                        />
                      )}
                    </Menu.Item>
                  ))}
                {status !== 'addWishlist' && status !== 'removeFromWishlist' && (
                  <Menu.Item
                    as="div"
                    className={`${
                      wishlistsState.data?.length ? 'pt-2.5' : 'pt-4 md:pt-[15px]'
                    } whitespace-nowrap pb-[15px] pl-4 pr-5 hover:bg-a-darker-gray cursor-pointer`}
                    style={itemStyles}
                    onClick={(e) => {
                      e.preventDefault();
                      setStatus('addWishlist');
                    }}>
                    <button
                      className="bg-gray-800 w-[42px] h-[27px] mr-2.5 align-middle"
                      style={{ paddingLeft: '11px' }}>
                      <PlusIcon style={{ height: '14px' }} />
                    </button>
                    <span
                      className="whitespace-nowrap text-[12px] md:text-[14px] leading-22 overflow-hidden inline-block align-middle max-w-[210px]"
                      style={itemStyles}>
                      Create New Wishlist
                    </span>
                  </Menu.Item>
                )}
                {status === 'addWishlist' && (
                  <Menu.Item className="w-full max-w-[553px] shadow-xl" as="div" onClick={(e) => e.preventDefault()}>
                    <CreateOrUpdateWishlistForm
                      action="create"
                      minimal={true}
                      completedCallback={(wishlist) => {
                        addProduct(wishlist);
                        close();
                      }}
                      onClose={close}
                    />
                 </Menu.Item>
                )}
                {status === 'removeFromWishlist' && (
                  <Menu.Item
                    as="div"
                    className="py-[15px] flex justify-center hover:bg-a-darker-gray cursor-pointer"
                    style={itemStyles}
                    onClick={(e) => {
                      e.preventDefault();
                    }}>
                    <button
                      onClick={(e) => {
                        setRemoveFromWishlistConfirmationOpen(true);
                        close();
                        e.preventDefault();
                      }}
                      className={
                        'bg-a-blue hover:bg-a-light-blue text-white text-16 leading-22 font-medium w-[209px] rounded-[5px] py-2.5 px-2'
                      }>
                      Remove From Wishlist
                    </button>
                  </Menu.Item>
                )}
              </MenuItemsCustomWrapper>
            )}
          </>
        )}
      </Menu>
      {status == 'loading' && <Loader />}
      <Dialog
        open={!user && status == 'active'}
        onClose={() => {
          setStatus('pending');
        }}
        className="relative z-[51]">
        <div
          className="fixed inset-0 flex items-center justify-center overflow-y-auto bg-black/60"
          onClick={(e) => {
            const target = e.target as Element;
            if (!target.classList.contains('unlock-modal')) {
              setStatus('pending');
            }
          }}>
          <Dialog.Panel>
            <div className="unlock-modal bg-gray-900 shadow-lg drop-shadow-lg rounded-[5px] p-4 text-white">
              {!user && (
                <div className="max-w-[300px]">
                  <p className="text-[16px] text-center">
                    You must have an ActionVFX account to use the wishlists feature. Please{' '}
                    <a
                      href="#/sign-in"
                      onClick={() => {
                        setStatus('inactive');
                        dispatch({ type: AUTH_SHOW_LOGIN });
                        setIsOpen(false);
                      }}
                      className="text-a-blue">
                      login
                    </a>{' '}
                    to continue.
                  </p>
                </div>
              )}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
      <YesNoPromptDialog
        promptText="Are you sure you want to remove this item from the wishlist?"
        dialogOpen={removeFromWishlistConfirmationOpen}
        onResult={(value: boolean) => {
          if (value) removeProduct(contextWishlistId);
          setRemoveFromWishlistConfirmationOpen(false);
        }}
        onDialogClosed={() => setRemoveFromWishlistConfirmationOpen(false)}
      />
    </div>
  );
}

export default AddToWishlistButton;
